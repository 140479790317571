import React from "react";
import "./style.css";

const Footer = () => {
  return (
    <footer id="footer" className="-hide-print">
      <div className="wrapper">
        <ul className="social">
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://twitter.com/afk_mario"
            >
              tw
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="http://twitter.com/afk_mario"
            >
              fb
            </a>
          </li>
          <li>
            <a
              rel="me noreferrer noopener"
              target="_blank"
              href="https://merveilles.town/@mario_afk"
            >
              mast
            </a>
          </li>

          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/mariocarballo/"
            >
              lkdn
            </a>
          </li>
        </ul>
        <span className="year">© {new Date().getFullYear()}</span>
      </div>
    </footer>
  );
};

export default Footer;
